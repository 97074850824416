import React, { ReactElement } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ConnectForm } from './contactForm';

const ContactFormEn = (): ReactElement => {
    return (
        <ConnectForm>
            {({ register }: UseFormReturn<any>) => (
                <>
                    <div className="field">
                        <label htmlFor="name" className="label">
                            Name
                        </label>
                        <div className="control">
                            <input
                                {...register('name')}
                                id="name"
                                name="name"
                                className="input"
                                type="text"
                                required
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="age" className="label">
                            Age
                        </label>
                        <div className="control">
                            <input
                                id="age"
                                {...register('age')}
                                name="age"
                                className="input"
                                type="number"
                                required
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="email" className="label">
                            Email
                        </label>
                        <div className="control">
                            <input
                                id="email"
                                {...register('email')}
                                name="email"
                                className="input"
                                type="email"
                                required
                            />
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                type="checkbox"
                                {...register('experience')}
                                name="experience"
                                id="experience"
                            />

                            <label htmlFor="experience" className="checkbox ml-3">
                                I have martial arts experience
                            </label>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Message</label>
                        <div className="control">
                            <textarea
                                {...register('message')}
                                name="message"
                                className="textarea"
                            />
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <button type="submit" className="button is-link is-primary">
                                Send
                            </button>
                        </div>
                    </div>
                </>
            )}
        </ConnectForm>
    );
};

export default ContactFormEn;
