import React from 'react';
import ContactForm from '@components/contactForm';
import ContactFormEn from '@components/form.en';
import FormContainer from '@components/formContainer';

const Contact = (): React.ReactElement => {
    return (
        <div className="container">
            <h1 className="title is-size-4 pl-4">Contact us</h1>
            <p className="pl-4 mb-4">
                If you are interested to know more about Kenshikai Karate-Do, please leave your
                contact information and a short description of your previous experiences with
                martial arts.
            </p>
            <FormContainer>
                <h2>Head instructor</h2>
                <p>Kimmo Viitaharju, 5. dan</p>
                <p>kimmo.kenshikai@gmail.com</p>
            </FormContainer>
            <br />

            <ContactForm>
                <ContactFormEn />
            </ContactForm>
        </div>
    );
};

export default Contact;
